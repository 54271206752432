import { utcToZonedTime } from 'date-fns-tz';
import { ExcelReportOptions } from '@components/create-excel/create-excel';
import { IAccount } from '@interfaces/accounts/i-account';
import { ITransactionsState } from '@store/transactions/reducer';
import ReportType from '../../enums/type-reports';

const excelEndpoint = {
  [ReportType.AllTransactions]: 'reports/all-transactions',
  [ReportType.Usages]: 'reports/usage',
  [ReportType.SalesByCard]: 'reports/sales-by-cards',
  [ReportType.SalesByLocations]: 'reports/sales-by-locations',
};
const generateExcelOptions = (
  reportType: ReportType,
  dates: ITransactionsState['list']['dates'],
  account?: Pick<IAccount, 'id' | 'name'>,
): ExcelReportOptions => {
  let period = '';

  if (dates?.startDate || dates?.endDate) {
    // UTC
    const { startDate, endDate } = dates;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const sDate = startDate
      ? new Date(utcToZonedTime(`${startDate}Z`, timezone)).toDateString()
      : '';
    const eDate = endDate ? new Date(utcToZonedTime(`${endDate}Z`, timezone)).toDateString() : '';

    console.log({ startDate, endDate, sDate, eDate });

    period = `${startDate ? `from ${sDate}` : ''}${startDate && endDate ? ' - ' : ''}${
      endDate ? `to ${eDate}` : ''
    }`;
  }

  const fileName = `${reportType} ${account ? account.name : ''} ${period}`.trim();

  return {
    fileName,
    requestOptions: {
      params: {
        accountId: account?.id,
        startDate: dates?.startDate ? dates.startDate : undefined,
        endDate: dates?.endDate ? dates.endDate : undefined,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      endpoint: excelEndpoint[reportType],
    },
  };
};

export default generateExcelOptions;
